import Vue from "vue";
import AgoraRTC from "agora-rtc-sdk-ng";
import { agoraHelper } from "./helper";
import { store } from "@/store";
import { router } from "@/router";
import { filterArray } from "@/utils/helpers";

export default class RTCClient {
	constructor() {
		// Options for joining a channel
		this.options = {
			appId: null,
			channel: null,
			token: null,
			mode: null,
			codec: null,
		};
		// For the local client.
		this.rtc = {
			uid: null,
			client: null,
			audioTrack: null,
			videoTrack: null,
		};
		// For Screen Client
		this.screen_rtc = {
			client: null,
			uid: null,
			screenTrack: null,
		};
		// A flag to check whether client join the call or not
		this.joined = false;
		// A flag to check whether client has shared the screen or not.
		this.screenShared = false;
		// recording constants
		this.recording = {
			// It is required to start recording of a call
			resourceId: null,
			// Mode type can be individual or composite
			modeType: null,
			// This returns when call recording start successfully
			sid: null,
		};
		// Any newly opened window's instance
		this.new_window = null;
		// Devices
		this.audioDevices = null;
		this.videoDevices = null;
		// Volume
		this.audioLevel = 100;
	}

	isRecordingRoute() {
		return router.currentRoute.name == "AgoraRecordingPage";
	}

	async retrieveAndSetCredentials() {
		let response = await store.dispatch("agora/retrieveToken", {
			channel_name: router.currentRoute.params.channel_name,
		});

		// If response is not having token that means input channel is invalid.
		if (!response.Token) {
			throw new Error("app.ntfy.err.invalid_input_channel", {
				cause: "werCustom",
			});
		}

		// Set response in local data
		this.options.codec = response.Codec;
		this.options.mode = response.Mode;
		this.options.token = response.Token;
		this.options.appId = response.appID;
		this.options.channel = response.channelName;
	}

	/*-------------------------------------------------------
        | START VIDEO CALL...
        -------------------------------------------------------*/
	async startVideoCall() {
		// Retrieve token and other IMP. credentials of requested channel.
		await this.retrieveAndSetCredentials();

		// Check which devices are available or not
		await this.checkDevicesAvailability();

		if (!this.audioDevices.length && !this.videoDevices.length) {
			throw new Error("app.ntfy.err.no_media_is_on", {
				cause: "werCustom",
			});
		}
		// Create client
		this.rtc.client = await AgoraRTC.createClient({
			mode: this.options.mode,
			codec: this.options.codec,
		});

		// Listen if any remote user is joining the same channel
		this.listenRemoteUsers();

		// Allow local user to join channel
		await this.localUserJoiningChannel();

		// Media track should always create, Except "recording_route"
		if (!this.isRecordingRoute()) {
			await this.createAndPublishLocalTracks();

			// Start camera and microphone
			await this.playLocalTracks();
		}
		// Successfully joined channel or call started successfully.
		this.joined = true;
	}

	async checkDevicesAvailability() {
		let devices = await AgoraRTC.getDevices();
		// get audio devices
		this.audioDevices = filterArray(devices, "kind", "audioinput", true);
		// get video devices
		this.videoDevices = filterArray(devices, "kind", "videoinput", true);
	}

	/*-------------------------------------------------------
        | VIDEO CALLING JOINING METHODS FOR LOCAL USER.
        -------------------------------------------------------*/

	async localUserJoiningChannel() {
		this.rtc.uid = await this.rtc.client.join(
			this.options.appId,
			this.options.channel,
			this.options.token,
			null,
		);
	}

	async createAndPublishLocalTracks() {
		let tracks = [];

		// Create an audio track from the audio sampled by a microphone.
		this.rtc.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
		tracks.push(this.rtc.audioTrack);

		// Create a video track from the video captured by a camera.

		if (this.videoDevices.length) {
			this.rtc.videoTrack = await AgoraRTC.createCameraVideoTrack();
			tracks.push(this.rtc.videoTrack);
		}

		// Publish the local audio and video tracks to the channel.
		await this.rtc.client.publish(tracks);
	}

	async playLocalTracks() {
		/**
		 * First create a container in which you want to play the media tracks.
		 * Then, specify the ID of the DIV container. Used the `uid` of the remote user.
		 */

		let playerContainer = await agoraHelper.createPlayerContainer(
			this.rtc.uid,
			this.videoDevices.length,
		);

		// If video device found then play video track, NO NEED TO PLAY AUDIO TRACK
		if (this.videoDevices.length) {
			this.rtc.videoTrack.play(playerContainer.id);
		}

		// Manage player's styles like button and link styles
		agoraHelper.manageStyleOfPlayer();
	}

	/*-------------------------------------------------------
        | VIDEO CALLING LEAVING METHODS FOR LOCAL USER.
        -------------------------------------------------------*/

	async closeAndRemoveLocalTracks() {
		// Destroy local container
		if (this.rtc.uid) {
			agoraHelper.removePlayerContainer(this.rtc.uid);
		}
		// Destroy the local audio and video tracks.
		if (this.rtc.audioTrack) {
			await this.rtc.audioTrack.close();
		}
		if (this.rtc.videoTrack) {
			await this.rtc.videoTrack.close();
		}
	}

	async localUserLeavingChannel() {
		if (this.rtc.client) {
			await this.rtc.client.leave();
		}
	}

	/*-------------------------------------------------------
        | VIDEO CALLING JOINING METHODS FOR REMOTE USERS.
        -------------------------------------------------------*/

	async listenRemoteUsers() {
		let self = this;
		this.rtc.client.on("user-published", async (user, mediaType) => {
			try {
				await self.remoteUserJoiningChannel(user, mediaType);
			} catch (error) {
				Vue.prototype.$announce.error(error);
			}
		});
		this.rtc.client.on("user-unpublished", async (user) => {
			try {
				await agoraHelper.removePlayerContainer(user.uid);
			} catch (error) {
				Vue.prototype.$announce.error(error);
			}
		});

		// Enable the volume indicator for client
		this.rtc.client.enableAudioVolumeIndicator();

		// Listen volume level in every 2 seconds(automatically)
		this.rtc.client.on("volume-indicator", (volumes) => {
			volumes.forEach((volume, index) => {
				if (Math.floor(volume.level) === 0) {
					agoraHelper.insertMuteElement(volume.uid);
				} else {
					agoraHelper.removeMuteElement(volume.uid);
				}
			});
		});
	}

	async remoteUserJoiningChannel(user, mediaType) {
		// Subscribe to a remote user.
		await this.rtc.client.subscribe(user, mediaType);

		await this.playRemoteTracks(user, mediaType);
	}

	async playRemoteTracks(user, mediaType) {
		// If the subscribed track is audio.
		if (mediaType === "audio") {
			// No need to pass DOM element's id when playing the audio track.
			user.audioTrack.play();
		}

		// If the subscribed track is video.
		if (mediaType === "video") {
			let playerContainer = await agoraHelper.createPlayerContainer(user.uid);
			/*
			 * Pass the DIV container and the SDK dynamically creates a player in the container
			 * for playing the remote video track.
			 */
			user.videoTrack.play(playerContainer);
		}

		// If user has no video device
		if (!user.hasVideo) {
			await agoraHelper.createPlayerContainer(user.uid, false);
		}

		agoraHelper.manageStyleOfPlayer();
	}

	/*-------------------------------------------------------
        | VIDEO CALLING LEAVING METHODS FOR REMOTE USERS.
        -------------------------------------------------------*/

	removeRemoteUsersTracks() {
		if (this.rtc.client.remoteUsers.length) {
			this.rtc.client.remoteUsers.forEach(async (user) => {
				await agoraHelper.removePlayerContainer(user.uid);
			});
		}
	}

	/*-------------------------------------------------------
        | END VIDEO CALL
        -------------------------------------------------------*/

	async endVideoCall() {
		await this.closeAndRemoveLocalTracks();

		await this.removeRemoteUsersTracks();

		// If screen is shared
		if (this.screen_rtc.screenTrack) {
			this.stopScreenSharing();
		}

		// If recording is started
		if (this.recording.sid) {
			await this.stopCallRecording();
		}

		await this.localUserLeavingChannel();

		this.joined = false;

		// Reset Data
		Object.keys(this.rtc).forEach((key) => {
			this.rtc[key] = null;
		});

		/**
		 * Close current window if it is not a recording route
		 * because recording route opens in same window of
		 * project and calling route opens in another window
		 */
		if (!this.isRecordingRoute()) {
			window.close();
		}
	}

	/*-------------------------------------------------------
        | RECORDING METHODS
        -------------------------------------------------------*/

	beforeStartCallRecording() {
		// Open a new user in a new window, because to record single user a dummy should present in the channel
		let self = this;
		this.new_window = window.open(
			`${Vue.prototype.$environments.base_app_url}/research/agora/index/channel/${this.options.channel}/true`,
			"Don't close",
			"width=300,height=300",
		);
		// Minimize this opened window after a second
		setTimeout(() => {
			if (self.win) {
				self.win.blur();
			}
		}, 2000);
	}

	async acquireResourceId() {
		let response = await store.dispatch("agora/acquireResourceId", {
			channel_name: `${this.options.channel}`,
			uid: `${this.rtc.uid}`,
		});
		this.recording.resourceId = response.resourceId;
	}

	async startCallRecording() {
		// 0. Do some setup before start recording
		if (router.currentRoute.name == "AgoraRecordingPage") {
			await this.beforeStartCallRecording();
		}

		// 1. Get resource id, which is mandatory to start recording
		await this.acquireResourceId();

		// 2. Start recording
		if (this.recording.resourceId) {
			let response = await store.dispatch("agora/startRecording", {
				uid: `${this.rtc.uid}`,
				channel_name: `${this.options.channel}`,
				token: `${this.options.token}`,
				resource_id: `${this.recording.resourceId}`,
			});
			this.recording.sid = response.sid;
		}
	}

	async getRecordingStatus() {
		if (this.recording.resourceId && this.recording.sid) {
			await store.dispatch("agora/queryRecordingStatus", {
				resource_id: `${this.recording.resourceId}`,
				sid: `${this.recording.sid}`,
			});
		}
	}

	async stopCallRecording() {
		// 1. Stop recording
		let response = await store.dispatch("agora/stopRecording", {
			uid: `${this.rtc.uid}`,
			channel_name: `${this.options.channel}`,
			resource_id: `${this.recording.resourceId}`,
			sid: `${this.recording.sid}`,
		});

		// 2. Reset Data
		Object.keys(this.recording).forEach((key) => {
			this.recording[key] = null;
		});

		return response;
	}

	async closeNewWindow() {
		if (this.new_window !== null) {
			this.new_window.close();
			this.new_window = null;
		}
	}

	/*-------------------------------------------------------
        | SCREEN SHARING METHODS
        -------------------------------------------------------*/
	async shareScreen() {
		this.screen_rtc.client = AgoraRTC.createClient({
			mode: this.options.mode,
			codec: this.options.codec,
		});

		await this.screenUserJoiningChannel();

		await this.createAndPublishScreenTracks();

		agoraHelper.manageStyleOfPlayer();
	}

	async screenUserJoiningChannel() {
		this.screen_rtc.uid = await this.screen_rtc.client.join(
			this.options.appId,
			this.options.channel,
			this.options.token,
			null,
		);
	}

	async createAndPublishScreenTracks() {
		this.screen_rtc.screenTrack = await AgoraRTC.createScreenVideoTrack();
		this.screenShared = true;
		await this.screen_rtc.client.publish(this.screen_rtc.screenTrack);
	}

	/*-------------------------------------------------------
            | STOP SHARING SCREEN METHODS
            -------------------------------------------------------*/

	async stopScreenSharing() {
		await this.removeAndCloseScreenTracks();

		await this.screenUserLeavingChannel();

		this.screenShared = false;

		// Reset Data
		Object.keys(this.screen_rtc).forEach((key) => {
			this.screen_rtc[key] = null;
		});
	}

	removeAndCloseScreenTracks() {
		if (this.screen_rtc.screenTrack) {
			this.screen_rtc.screenTrack.close();
		}
		if (this.screen_rtc.uid) {
			agoraHelper.removePlayerContainer(this.screen_rtc.uid);
		}
	}

	async screenUserLeavingChannel() {
		if (this.screen_rtc.client) {
			await this.screen_rtc.client.leave();
		}
	}

	async toggleMute() {
		this.audioLevel = this.audioLevel == 100 ? 0 : 100;
		this.rtc.audioTrack.setVolume(this.audioLevel);
	}
}
