// Call layout optimise event on every time window resize
window.addEventListener(
	"load",
	function(event) {
		agoraHelper.optimiseLayout();
		window.onresize = agoraHelper.optimiseLayout;
	},
	false,
);

export const agoraHelper = {
	optimiseLayout() {
		let Margin = 2;
		let Scenery = document.getElementById("agora-layout-container");
		if (!Scenery) return;

		let Width = Scenery.offsetWidth - Margin * 2;
		let Height = Scenery.offsetHeight - Margin * 2;

		let Cameras = document.getElementsByClassName("agora-camera-container");
		if (!Cameras.length) return;

		let max = 0;
		let inc = 1;
		let total_cameras = Cameras.length;
		while (inc < 5000) {
			let i = 0;
			let w = 0;
			let h = inc * 0.75 + Margin * 2;
			while (i < total_cameras) {
				if (w + inc > Width) {
					w = 0;
					h = h + inc * 0.75 + Margin * 2;
				}
				w = w + inc + Margin * 2;
				i++;
			}
			let area = h > Height ? false : inc;
			if (area === false) {
				max = inc - 1;
				break;
			}
			inc++;
		}

		// set styles
		max = max - Margin * 2;
		for (var s = 0; s < total_cameras; s++) {
			Cameras[s].style.width = max + "px";
			Cameras[s].style.margin = Margin + "px";
			Cameras[s].style.height = max * 0.75 + "px";
		}
	},

	createPlayerContainer(user_id, user_has_camera = true) {
		let parent_el = document.getElementById("agora-layout-container");

		// 1. Create camera container and append it to the 'agora layout container'
		let cameraContainer = this.createCameraContainer(user_id, user_has_camera);
		parent_el.append(cameraContainer);

		// 2. Create name container and append it to the 'cameraContainer
		// let nameContainer = this.createNameContainer(user_name);
		// cameraContainer.append(nameContainer);

		// 3. Optimise layout container dimensions
		this.optimiseLayout();

		// 4. Return element for reference
		return cameraContainer;
	},

	createCameraContainer(user_id, user_has_camera) {
		let el = document.createElement("div");
		el.id = user_id.toString();

		// Assign vuetify classes to this
		let classes = ["agora-camera-container"];

		el.classList.add(...classes);

		// If user has no camera device then add an icon inside this.
		if (!user_has_camera) {
			el.classList.add(...["agora-no-camera-container"]);
			el.innerHTML = `<i aria-hidden='true' class='mdi mdi-account-circle agora-user-icon'></i>`;
		}

		return el;
	},

	createNameContainer(user_name) {
		let el = document.createElement("span");
		el.classList.add(...["agora-name-container"]);
		el.innerText = user_name;

		return el;
	},

	removePlayerContainer(user_id) {
		let element = document.getElementById(user_id);
		element && element.remove();
	},

	manageStyleOfPlayer() {
		var videoTags = document.getElementsByClassName("agora_video_player");
		for (var i = 0; i < videoTags.length; i++) {
			// Agora video player auto transform the image in 180deg, so removed it.
			videoTags[i].style.transform = null;
			/**
			 * By default, Agora video player does not consist any controls but
			 * when switch to full-screen mode it still shows "picture-to-picture" control
			 *  of a html video player. So, disable it for all time.
			 */
			videoTags[i].setAttribute("disablePictureInPicture", true);
			// It means that custom control (full_screen) is not added yet
			if (!videoTags[i].nextSibling) {
				this.addFullScreenControlToPlayer(videoTags[i]);
			}
		}
	},

	addFullScreenControlToPlayer(playerElement) {
		let btn = document.createElement("button");
		btn.className = "fullScreenBtn";
		btn.innerHTML = '<i aria-hidden="true" class="mdi mdi-fullscreen"></i>';

		// When button will be clicked then respected video player will take full-screen mode
		btn.addEventListener("click", function() {
			playerElement.requestFullscreen();
		});

		// Insert the custom control next to the player
		playerElement.parentNode.insertBefore(btn, playerElement.nextSibling);
	},

	findMuteElement(user_id) {
		return document.getElementById(`${user_id.toString()}-agora-mute-icon`);
	},

	insertMuteElement(user_id) {
		// Insert mute element only if it is not injected already
		if (!this.findMuteElement(user_id)) {
			let el = document.getElementById(user_id);
			if (!el) return;

			let mute_el = document.createElement("i");
			mute_el.setAttribute("aria-hidden", "true");
			mute_el.id = `${user_id.toString()}-agora-mute-icon`;
			mute_el.classList.add(...["mdi", "mdi-microphone-off", "agora-mute-icon"]);
			el.append(mute_el);
		}
	},

	removeMuteElement(user_id) {
		let mute_el = this.findMuteElement(user_id);
		if (!mute_el) return;

		mute_el.remove();
	},
};
